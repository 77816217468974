import { graphql, navigate } from "gatsby"
import React, { useEffect, useState } from "react"
import PaymentStatus from "../../containers/PaymentStatus"
import { getTransactionId } from "../../utils"

const PaymentStatusPage = () => {
  const [paymentId, setPaymentId] = useState<string>()

  useEffect(() => {
    const tid = getTransactionId()

    if (!tid) {
      navigate("/404")
      return
    }

    setPaymentId(tid)
  }, [])

  return <PaymentStatus paymentId={paymentId} />
}

export default PaymentStatusPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["common", "payment_success"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
